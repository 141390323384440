<template>
  <div class="contain">
    <!--确认申请弹窗-->
    <div class="cancelOrderModal" v-if="ok">
      <div class="modalBox">
        <div class="tit">提示</div>
        <div class="desc">确定提交退款申请吗？</div>
        <div class="btnRow">
          <div class="btn cancel" @tap="cancel">取消</div>
          <button class="btn confirm" @tap="send">确定</button>
        </div>
        <ivew class="line"></ivew>
      </div>
    </div>

    <!--底部浮动box-->
    <div class="bottom" @tap="openRefundModal">提交退款申请</div>
    <div class="main">
      <!--订单信息-->
      <div class="whiteBlock info">
        <div class="item">
          <span class="tit">订单号</span>
          <span>{{ orderDetail.orderCode }}</span>
        </div>
        <div class="item">
          <span class="tit">下单时间</span>
          <span>{{ orderDetail.orderCreateTime }}</span>
        </div>
        <div class="item">
          <span class="tit">实付金额</span>
          <span>{{ orderDetail.totalPrice }}</span>
        </div>
      </div>

      <!--申请资料-->
      <div class="whiteBlock info">
        <div class="item">
          <span class="tit">退款原因</span>
          <picker bindchange="pickerChange" :value="reason" :range="reasonArr">
            <div class="picker">
              <span v-if="!reason" style="color: #999">请选择 ></span>{{ reason
              }}{{ reason ? ' >' : '' }}
            </div>
          </picker>
        </div>
        <div class="item">
          <span class="tit">申请退款金额</span>
          <span style="color: #e71f19">￥{{ orderDetail.totalPrice }}</span>
        </div>
        <div class="item">
          <span class="tit">退款说明</span>
          <input
            maxlength="200"
            placeholder="选填，1-200字"
            style="text-align: right"
            :value="desc"
            bindinput="changeDesc"
            placegolder-class="placeholder"
          />
        </div>
      </div>

      <!--上传图片-->
      <div class="whiteBlock upload">
        <div class="tit">图片凭证（选填）</div>
        <div class="imgList">
          <div class="img" v-for="item in imgList" :key="item">
            <img :src="item" />
            <div class="del">-</div>
          </div>
          <div class="img" @tap="chooseImage" v-if="imgList.length < 3">
            <img
              src="https://cybertron-public.neolix.net/NeolixData/Default/frontend/AlipayImage/upload-pic.png"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import request from "@/utils/request";

export default {
  name: 'Refund',
  components: {},
  data() {
    return {
      orderCode: null,
      reason: '',
      orderDetail: {},
      imgList: [], //图片的本地路径组数
      imgUrlList: [], //图片的oss路径数组
      desc: '',
      ok: false,
      reasonArr: [
        '柜门无法打开',
        '系统显示有货，实际无货',
        '取错货物',
        '图片与实物不符',
        '商品质量有问题',
        '缺少餐具或餐品',
        '其他',
      ],
    };
  },

  created: function () {
    this.getOrderDetail();
  },

  mounted: function () {
    console.log(this.$route.query);
  },

  methods: {},
};
</script>

<style scoped lang="scss">
.contain {
  width: 100%;
  height: 100%;
  background: #f5f5f5;
  padding-bottom: 1.44rem;
  float: left;

  .main {
    padding: 0 0.3rem;

    & > .info {
      padding: 0.3rem;

      .item {
        min-height: 0.36rem;
        margin-top: 0.24rem;
        font-size: 0.28rem;
        color: #222;
        display: flex;
        justify-content: space-between;
        align-items: center;

        &:first-child {
          margin-top: 0;
        }

        .tit {
          color: #999;

          & + span {
            max-width: 4.7rem;
            text-align: right;
          }
        }
      }
    }
    & > .upload {
      padding: 0.3rem;

      .tit {
        font-size: 0.28rem;
        color: #222;
        font-weight: bold;
      }
      .imgList {
        display: flex;
        margin: 0.3rem 0 0.1rem;

        .img {
          width: 1.6rem;
          height: 1.6rem;
          margin-right: 0.3rem;
          position: relative;

          image {
            width: 100%;
            height: 100%;
          }
          .del {
            width: 0.4rem;
            height: 0.4rem;
            line-height: 0.3rem;
            text-align: center;
            background: #e71f19;
            /*display: flex;*/
            /*justify-content: center;*/
            /*align-items: center;*/
            color: #fff;
            border-radius: 50%;
            z-index: 2;
            position: absolute;
            top: -0.8rem;
            right: -0.8rem;
            font-weight: bold;
            font-size: 0.42rem;
          }
        }
      }
    }
  }
  .whiteBlock {
    background: #fff;
    border-radius: 0.08rem;
    margin-top: 0.2rem;
    padding: 0 0.3rem;
  }
  .bottom {
    width: 4.7rem;
    height: 0.88rem;
    line-height: 0.88rem;
    text-align: center;
    background: #f6c24a;
    border-radius: 0.54rem;
    font-size: 0.32rem;
    color: #222;
    font-weight: bold;
    position: fixed;
    bottom: 0.48rem;
    left: 1.4rem;
  }
  .cancelOrderModal .modalBox .desc {
    padding: 0.3rem 0 0.4rem;
  }

  .placeholder {
    color: #999;
  }
}
</style>
